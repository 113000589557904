<template>
  <div v-if='payStatus == "paid"'
    id='receipt-print' class='receipt-print relative border' style='height: 276mm;'>
    <div>
      <div class='bg-gray-100 w-full p-12'>
        <h1 class='text-5xl font-bold text-gray-700 text-center'>영수증</h1>
      </div>
      <div 
        class='flex flex-col justify-center items-center gap-y-1 z-10 p-4 relative'
        style='font-family: serif;'>
        <img :src='watermarkImage' id='watermark-image' class='z-0 top-12 p-4 absolute opacity-10 w-1/2'/>
        <div class='w-full text-2xl px-8 mt-16'>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>성명</div>
            <div class='text-right'>{{ nameString }}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>소속</div>
            <div class='text-right'>{{ hospitalName }}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>등록비</div>
            <div class='text-right'>{{ formattedFeeString(registrationFee) }}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>교재비</div>
            <div class='text-right'>{{ formattedFeeString(buyPrintedTextbook) }}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>합계</div>
            <div class='text-right'>{{ formattedFeeString(totalFeeAmount) }}</div>
          </div>
          <div class='flex flex-row justify-between py-3 border-t border-b'>
            <div class='font-semibold text-gray-800 flex-shrink-0'>행사명</div>
            <div class='text-right'>2024년 제17회 가톨릭대학교<br>의과대학 내과 연수강좌</div>
          </div>
          <div class='text-lg mt-20 text-center'>
            상기 금액을 정히 영수함.
          </div>
          <div class='text-lg p-8 mb-8 text-center tracking-widest'>
            2024년 2월 4일(일)
          </div>
          <div class='text-2xl font-semibold text-center' style='font-family: sans-serif;'>
            <img id='seal-image' :src='sealImage' class='inline-block max-w-md'/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if='payStatus == "unpaid"'>
     <div class='max-w-3xl p-24'>
      등록비가 입금되지 않았습니다.<br>
      <span class="font-semibold text-red-600">등록비 입금 여부 확인은 영업일 기준 3-5일이 소요</span>됩니다.<br>
      계좌내역을 일일이 확인하여 입금 여부를 체크하고 있기 때문에 시일이 다소 소요됩니다.<br>
      입금하신 분들은 누락 없이 체크하고 있으니, 조금만 기다려 주시면 감사하겠습니다.<br>
      등록비 납부 내역이 확인되면 등록 완료 안내 메일이 발송됩니다.</div>
  </div>
  <div v-else-if='payStatus == "unknown"'>
    <div class='max-w-2xl p-24'>영수증을 조회할 수 없습니다.</div>
  </div>
  <div v-else>
    <div class='max-w-2xl p-24'><loader-icon class='spinner inline-block' /> Loading</div>
  </div>
</template>

<script>
import { LoaderIcon } from 'vue-tabler-icons'
import { mapActions } from 'vuex'
import html2pdf from 'html2pdf.js'
import numbro from 'numbro'

export default {
  name: 'ReceiptDownloadFile',
  components: {
    LoaderIcon,
  },
  props: [
    'layout',
  ],
  data () {
    return {
      receiptData: {},
      payStatus: ''
    }
  },
  computed: {
    pdfOptions () {
      return {
        margin:       0,
        filename:     `${this.receiptData.name}_receipt.pdf`,
        image:        { type: 'png', quality: 0.98 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
    },
    totalFeeAmount () {
      return parseInt(this.receiptData.registrationData.registrationFee.replace(/\D/g, ''))
    },
    registrationFee () {
      return this.totalFeeAmount - this.buyPrintedTextbook
    },
    buyPrintedTextbook () {
      return this.receiptData.registrationData.buyPrintedTextbook === 'buy_printed_textbook' ? 20000 : 0
    },
    nameString () {
      return this.receiptData.name
    },
    hospitalName () {
      return this.receiptData.hospitalName
    },
    watermarkImage () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/cmccme/logos/cmccme_logo_icon.png`
    },
    sealImage () {
      return `https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/cmccme/cmccme_seal.png`
    }
  },
  methods: {
    ...mapActions('users', [
      'getPaidRegistrationDetailsForMe',
    ]),
    formattedFeeString (feeAmount) {
      return `${numbro(feeAmount).format({
        thousandSeparated: true,
      })} 원`
    },
    pdfCreate () {
      var element = document.getElementById('receipt-print')
      html2pdf().set(this.pdfOptions).from(element).save()
    },
    imageToBase64 (url) {
      return fetch(url, {mode: 'cors', cache: 'no-cache', credentials: 'omit'}).then(resp => {
        return resp.arrayBuffer()
      }).then((imageBuffer) => {
        return Buffer.from(imageBuffer).toString('base64')
      })
    },
    base64Images () {
      const watermarkPromise = this.imageToBase64(this.watermarkImage)
      const sealPromise = this.imageToBase64(this.sealImage)

      Promise.all([watermarkPromise, sealPromise]).then(values => {
        let image1 = document.getElementById('watermark-image')
        let image2 = document.getElementById('seal-image')
        let imagesLoadedCount = 0
        console.log('values', values)
        image1.src = `data:image/jpg;base64, ${values[0]}`
        image2.src = `data:image/jpg;base64, ${values[1]}`
        
        image1.onload = () => {
          imagesLoadedCount++
          if (imagesLoadedCount == 2) {
            this.pdfCreate()
          }
        }
        image2.onload = () => {
          imagesLoadedCount++
          if (imagesLoadedCount == 2) {
            this.pdfCreate()
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
    this.getPaidRegistrationDetailsForMe().then(resp => {
      if (resp.participantUnknown) {
        this.payStatus = 'unknown'
      } else if (resp.registrationData.payment) {
        this.receiptData = resp
        this.payStatus = 'paid'
        this.base64Images()
      } else {
        this.payStatus = 'unpaid'
      }
    })
    
  },
  beforeDestroy () {
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style lang='scss' scoped>
.spinner {
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>